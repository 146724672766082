import * as React from 'react'
import axios from 'axios'
import * as queryString from 'query-string'
import validator from 'validator'

import logo from '../images/logo.png'

import '../scss/password-reset.scss'

const PasswordNewPage = function({location}) {
    const [email, setEmail] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [sent, setSent] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const { token } = queryString.parse(location.search);

    async function submit() {
        setLoading(true)

        if (email !== '' && newPassword !== '') {
            if (validator.isEmail(email)) {
                try {
                    const response = await axios.post('https://reminder-api.bettershore-customers.com/resetpassword', {
                        username: email,
                        resetPasswordToken: decodeURI(token),
                        newPassword: newPassword,
                    });

                    console.log(response)

                    if (response.status === 200) {
                        setLoading(false)
                        setSent(true)
                    } else {
                        setLoading(false)
                        alert('Det skjedde en feil. Vennligst prøv igjen.')
                    }
                } catch (error) {
                    setLoading(false)

                    console.log(error)
                    console.log(error.response)

                    if (error.response && error.response.data && error.response.data.InvalidToken) {
                        alert('Lenken er ikke lenger gyldig fordi den har blitt brukt eller forsøkt brukt før. Du må få tilsendt en ny e-post for å nullstille passord.')
                    } else if (error.response && error.response.data && (error.response.data.PasswordRequiresDigit || error.response.data.PasswordRequiresUpper || error.response.data.PasswordTooShort)) {
                        alert(`Ugyldig passord. Passordet må:${'\n'}- Ha minst ett tall${'\n'}- Ha minst en stor bokstav${'\n'}- Være minimum 8 tegn langt`)
                    } else {
                        alert('Det skjedde en feil. Vennligst prøv igjen.')
                    }
                }
            } else {
                setLoading(false)
                alert('Du må skrive inn en gyldig e-postadresse.')
            }
        } else {
            setLoading(false)
            alert('Alle felter må fylles ut.')
        }
    }

    return (
        <div className="password-reset">
            <div className="wrapper">
                <a href="/" className="logo">
                    <img src={logo} alt=""/>
                </a>

                <a href="/" className="frontpage-link">Til forsiden</a>

                {
                    !sent &&
                    <>
                        <h1 className="title">Velg et nytt passord</h1>

                        <p className="intro">Skriv inn e-postadressen du bruker som ditt Reminder brukernavn og fyll inn passordet du ønsker å oppdatere til.</p>

                        <input disabled={loading} className="input" placeholder="Din e-postadresse.." type="text" onChange={(e) => { setEmail(e.target.value) }} />
                        <input disabled={loading} className="input" placeholder="Ditt nye passord.." type="password" onChange={(e) => { setNewPassword(e.target.value) }} />

                        <button onClick={() => { submit() }} disabled={loading} className="button">{loading ? 'Setter nytt passord...' : 'Sett nytt passord'}</button>
                    </>
                }

                {
                    sent &&
                    <>
                        <h1 className="title">Velg et nytt passord</h1>

                        <p className="intro">Supert! Du kan nå logge inn i Reminder-appen.</p>
                    </>
                }

                <div className="submenu">
                    <a href="/password-reset">Glemt passord?</a>
                    <a href="/terms">Vilkår og personvern</a>
                </div>
            </div>
        </div>
    )
}

export default PasswordNewPage
